<template lang="pug">
    .main-wrapper.endpoints-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Serviços Externos</b>
    
        Dialog.dialogApagar(header='Remover Endpoint' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover o endpoint <b>{{ dialogApagar_data.nm_endpoint }}</b>?
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='remove()')
    
        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid
                .p-col-12.p-md-6
                    label.form-label Nome do serviço:
                    .p-inputgroup
                        InputText(
                            placeholder='Nome do Serviço'
                            @keyup.enter.native='applyFilters()'
                            v-model='filters.nm_nome'
                        )
                .p-col-12.p-md-6
                    label.form-label Status:
                    Dropdown(v-model='filters.ie_status' :options='options.ie_status' @change=' applyFilters()'
                        optionLabel='label' optionValue='value' placeholder='Selecione' filter)
                        
        .ta-right.mt-4.mb-4
            Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/servicos-externos/salvar/0/')")
            
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            Panel.datatable(header='Lista de Serviços')
                DataTable.ta-center(:value="list")
                    Column(headerStyle='width: 10%;' field='nm_nome' header='Nome')
                    Column(headerStyle='width: 55%;' field='ds_endpoint' header='Endpoint')
                    Column(headerStyle='width: 8%;' field='ie_status' header='Status')
                        template(#body='props')
                            //- ProgressSpinner.waitingStatus(v-if='waitingStatus === props.data.id' strokeWidth='6')
                            .status-indicator(:class="{ off: ! props.data.ie_status }" )
                                    i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                    Column(headerStyle='width: 8%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="$router.push(`/servicos-externos/salvar/${ props.data.id }/`)"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-danger(
                                    v-tooltip.top="'Apagar'"
                                    icon="jam jam-minus-circle"
                                    @click="dialogApagar = true; dialogApagar_data = props.data"
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
    </template>
    <style lang="scss">
        .status-indicator {
			display: inline-block;
			font-size: 0;
			width: 24px;
			height: 24px;
			line-height: 36px;
			border-radius: 50%;
			background-color: #94c860;
			border: 1px solid #84b553;
			transition: 250ms;
			cursor: pointer;
			&:hover { background-color: #84b553; }
			&.off {
				background-color: #d33838;
				border: 1px solid #b33030;
				&:hover { background-color: #b33030; }
			}
			.jam {
				color: #fff;
			}
		}
    </style>
    <script>
        import ProgressBar from 'primevue/progressbar'
        import Panel from 'primevue/panel'
        import Paginator from 'primevue/paginator'
        import DataTable from 'primevue/datatable'
        import Column from 'primevue/column'
        import Button from 'primevue/button'
        import Tooltip from 'primevue/tooltip'
        import Dialog from 'primevue/dialog'
        import InputText from 'primevue/inputtext'
        import ProgressSpinner from 'primevue/progressspinner'
        import Dropdown from 'primevue/dropdown'
    
        import { ServicosExternos } from './../../middleware'
        import wsConfigs from './../../middleware/configs'
        export default {
            created () { this.applyFilters() },
            components: { ProgressBar, Panel, Paginator, DataTable,
                Column, Button, Tooltip, Dialog, ProgressSpinner, 
                Dropdown, InputText },
            directives: { tooltip: Tooltip },
            data () {
                return {
                    list: [],
                    windowInnerWidth: window.innerWidth,
                    waiting: false,
                    waitingApagar: false,
                    dialogApagar: false,
                    dialogApagar_data: {},
                    filters: {
                        nm_nome: '',
                        ie_status: '1',
                    },
                    options: {
                        ie_status: [
                            {value: null, label: 'Todos'},
                            {value: '1', label: 'Ativos'},
                            {value: '0', label: 'Inativos'},
                        ]
                    },
                    paginator: {
                        page: this.$route.query.pg ? this.$route.query.pg : 1,
                        per_page: wsConfigs.paginator_perPage,
                        count: 0
                    }
                }
            },
            methods: {
                getList (params) {
                    this.waiting = true
                    return ServicosExternos.findAll(params).then(response => {
                        if (response.status == 200) {
                            this.paginator.count = response.data.count
                            this.list = response.data.results
                        }
                        this.waiting = false
                        return true
                    })
                },
                applyFilters () {
                    let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                    if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                    Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
                    this.getList(params)
                },
                onPage (ev) {
                    this.paginator.page = ev.page + 1
                    this.applyFilters()
                },
                remove () {
                    this.waitingApagar = true
                    ServicosExternos.remove(this.dialogApagar_data.id).then(response => {
                        this.waitingApagar = false
                        if (([200, 201, 204]).includes(response.status)) {
                            this.$toast.info('Serviço removido com sucesso', { duration: 3000 })
                            this.dialogApagar = false
                            this.applyFilters()
                        } else if (response.data.detail) {
                            if (typeof response.data.detail == 'string')
                                this.$toast.error(response.data.detail, { duration: 3000 })
                            else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                        }
                    })
                }
            }
        }
    </script>